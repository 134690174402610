import {Pages}        from "./Pages";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import "jquery-validation/dist/localization/messages_ru";
import React          from "react";
import {FileUploader} from "../../modules/FileUploader";
import {Utils}        from "../Utils";
import {AlertBlock}   from "../widgets/AlertBlock";
const axios = require("axios").default;

export class SkipsEdit extends Pages {

    initPageHandlers(params) {
        super.initPageHandlers(params);

        this.initDateAndValidate();

        this.initFileUpload();

    }

    initDateAndValidate() {
        let $reason = $("#reason"),
            $period = $("#period"),
            $dateStart = $("#date_start"),
            $dateEnd = $("#date_end"),
            $endDateContainer = $("#end_date_container"),
            $labelStart = $("label[for='date_start']"),
            dateFormat = app.config.DEFAULT_DATEPICKER_DATE_FORMAT,
            dateStart = getDate($dateStart),
            dateEnd = getDate($dateEnd),
            defaultDateStart = (dateStart === null) ? "0" : dateStart,
            defaultDateEnd = (dateEnd === null) ? "0" : dateEnd,
            from = $dateStart
                .datepicker({
                    dateFormat: dateFormat,
                    defaultDate: defaultDateStart,
                    changeMonth: true,
                    changeYear: true,
                    numberOfMonths: 3,
                    beforeShow: Utils.correctInputPosition,
                })
                .on("change", function () {
                    let date = getDate(this);
                    to.datepicker("option", "minDate", date);
                    to.datepicker("option", "defaultDate", date);
                }),
            to = $dateEnd.datepicker({
                dateFormat: dateFormat,
                defaultDate: defaultDateEnd,
                changeMonth: true,
                changeYear: true,
                numberOfMonths: 3,
                beforeShow: Utils.correctInputPosition,
            });

        function getDate(element) {
            try {
                return $.datepicker.parseDate(dateFormat, element.value);
            } catch (error) {
                return null;
            }
        }

        $period.on("change", function (e) {
            $dateEnd.val("");
            if (this.checked) {
                $endDateContainer.show();
                $labelStart.html("Дата начала");
            } else {
                $endDateContainer.hide();
                $labelStart.html("Дата");
                $dateEnd.val($dateStart.val());
            }
        });

        // validate
        $("#submit_button").on("click touchstart", function (e) {

            if ($period.prop("checked") && $dateEnd.val() === "") {
                alert("Если выбран ввод даты за период, то необходимо заполнить дату окончания периода");
                return false;
            } else if ($dateStart.val() === "") {
                alert("Не установлена дата начала периода");
                return false;
            } else if ($reason.val() === "") {
                alert("Не заполнена причина пропуска!");
                return false;
            }

        });

    }

    initFileUpload() {
        let $docIdField = $("#docId"),
            $documentContainer = $("#documentContainer");

        let fileUploader = new FileUploader(
            $("#doc"),
            {
                fileExtensions: ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'pdf'],
                uploadDir: app.config.SKIP_REASON_FILE_DIR,
                docIdField: $docIdField,
                onFileUpload: onfileUpload,
                onFileRemove: onFileRemove,
            }
        );

        function onfileUpload(responseData) {
            // запишем новый docId в форму
            $("#documentContainer").remove();
            $docIdField.val(responseData);
        }

        function onFileRemove(responseData) {
            // запишем новый docId в форму
            $("#documentContainer").remove();
            $docIdField.val("");
        }

        $(document).on("click", "#docDeleteButton", fileUploader.removeFile);

    }

}
