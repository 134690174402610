import './Diary';
import {Utils}      from "../classes/Utils";
import {AlertBlock} from "../classes/widgets/AlertBlock";

const axios = require("axios").default;

/**
 * плагин jQuery
 * автор: ADES
 */
export function FileUploader(element, options) {
    "use strict";
    this.pluginName = 'FileUploader';
    this.defaults = {
        fileExtensions: "jpg,jpeg,png,gif,pdf",
        imageSize: 2097152, //2 мегабайта
        $removeFileButton: $("<button type=\"button\" class=\"btn btn-danger btn-sm ms-3 mb-1\">Удалить файл</button>"),
        onFileUpload: function () {
        },
        onFileRemove: function () {
        },
    };
    this.options = $.extend({}, this.defaults, options);
    this.name = this.pluginName;
    this._this = this;
    this.$body = $("body");
    this.element = element;
    this.$element = $(element);

    this.upload = this.upload.bind(this);
    this.removeFile = this.removeFile.bind(this);

    this.$element.on("change", this.upload);
}

FileUploader.prototype.upload = async function (event) {

    const confirmRes = await diary.getConfirm(
        "Вы действительно хотите загрузить файл?",
        event,
    );

    if (!confirmRes) {
        this.$element.val("");
        return false;
    }

    const fileData = this.$element.prop('files')[0],
        fileName = Utils.getRandomID(12),
        formData = new FormData();

    let $alert = new AlertBlock();

    formData.append('dir', this.options.uploadDir);
    formData.append('fileName', fileName);
    formData.append('doc', fileData);

    if (!this.validateFileType(fileData)) {
        this.$element.val("");
        $alert.remove();
        $alert.showError("Неверный формат файла!");
        $alert.getNode().insertBefore(this.$element);
        return false;
    }

    if (!this.validateFileSize(fileData)) {
        this.$element.val("");
        $alert.remove();
        $alert.showError("Превышен допустимый размер файла!");
        $alert.getNode().insertBefore(this.$element);
        return false;
    }

    // если docId > 0 значит ранее был установлен документ
    // его следует удалить
    if (this.options.docIdField.val() > 0) {
        this.deleteFileHandler();
    }

    axios.post("/api/docs/save/", formData)
         .then((response) => {
             let $alert = new AlertBlock();
             $alert.showSuccess(`Успешно загружен файл документа c ID ${response.data}!`);
             $alert.getNode().insertBefore(this.$element);
             this.options.$removeFileButton
                 .insertAfter(this.$element)
                 .on("click", this.removeFile);
             this.options.onFileUpload(response.data);
         })
         .catch(() => {
             let $alert = new AlertBlock();
             $alert.showError("Не удалось записать файла документа!");
             $alert.getNode().insertBefore(this.$element);
         });


};

FileUploader.prototype.removeFile = async function (event) {

    const confirmRes = await diary.getConfirm(
        "Вы действительно хотите хотите удалить документ?",
        event,
    );

    if (confirmRes) {
        return false;
    }

    this.deleteFileHandler();

};



FileUploader.prototype.deleteFileHandler = function () {
    const docId = this.options.docIdField.val();
    axios.delete(`/api/docs/${docId}/`)
         .then(() => {
                 let $alert = new AlertBlock();
                 $alert.showSuccess(`Успешно удалён файла документа с ID ${docId}!`);
                 $alert.getNode().insertBefore(this.$element);
                 this.$element.val("");
                 this.options.onFileRemove();
                 return true;
             },
         )
         .catch(() => {
             let $alert = new AlertBlock();
             $alert.showError("Не удалось выполнить запрос на удаление файла документа!");
             $alert.getNode().insertBefore(this.$element);
             return false;
         });
};

FileUploader.prototype.validateFileType = function (file) {
    return $.inArray(file.type.split('/')[1], this.options.fileExtensions) !== -1;
};

FileUploader.prototype.validateFileSize = function (file) {
    return file.size <= this.options.imageSize;
};
